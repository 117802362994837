import React from 'react';
import { Link, graphql } from 'gatsby';

import { FormattedMessage } from 'react-intl';
import { PageHelmet } from '../components/PageHelmet';
import LeftTextRightImage from '../components/SectionBlocks/LeftTextRightImage';
import PageHeader from '../components/PageHeader';
import HighlightListWithIcons from '../components/SectionBlocks/HighlightListWithIcons';
import RepeatableSectionSelector from '../components/SectionBlocks/RepeatableSectionSelector';
import LeftTitleRightDesc from '../components/SectionBlocks/LeftTitleRightDesc';
import TopHeadlineBottomDesc
  from '../components/SectionBlocks/TopHeadlineBottomDesc';
import SubsidiariesList from '../components/About/SubsidiariesList';

// ********************************* UI Template (Shared with CMS) *********************************
export const AboutSubPageTemplate = ({
  title,
  description,
  bgImage,
  headline,
  subsidiaries,
  sections,
  sectionsOne,
}) => (
  <div>
    <PageHelmet title={title} description={description} />
    <PageHeader
      title={<FormattedMessage id="corporate:title" defaultMessage="Corporate Profile" />}
      subtitle={<FormattedMessage id="corporate:subtitle" defaultMessage="About" />}
      bgImage={bgImage}
    />
    {sections && <RepeatableSectionSelector sections={sections} />}
    <TopHeadlineBottomDesc {...headline} />
    <SubsidiariesList {...subsidiaries} />
    {sectionsOne && <RepeatableSectionSelector sections={sectionsOne} />}
  </div>
);

// ********************************* Render page *********************************
const AboutSubPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <AboutSubPageTemplate {...frontmatter} />;
};

export default AboutSubPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query AboutSubPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        bgImage {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sections {
          title
          sectionType
          desc
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        headline {
          title
          desc
        }
        subsidiaries {
          intro
          desc
          companyList {
            name
            start
            people
            link
            img {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 1024, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        sectionsOne {
          title
          sectionType
          desc
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
