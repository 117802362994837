import React from 'react';
import styled from 'styled-components';
import { textAlign } from 'styled-system';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Column from '../Atoms/Column';
import Row from '../Atoms/Row';
import Paragraph from '../Atoms/Paragraph';
import theme from '../../styles/theme';
import Box from '../Atoms/Box';
import PreviewCompatibleImage from '../Atoms/PreviewCompatibleImage';

const Card = styled(Box)`
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
`;

const SubsidiariesList = ({ intro, companyList, desc }) => (
  <Section background={theme.color.lightBlue}>
    <Container>
      <Row multi>
        <Column col="is-10 is-offset-1" pt={[4, 4, 3]}>
          <Paragraph color={theme.color.blue} pb={[0, 0, 2]}>
            {intro}
          </Paragraph>
        </Column>
        <Column col="is-12">
          <Row multi className="is-centered">
            {companyList && companyList.map(company => (
              <Column textAlign={['center', 'center', 'left']} col="is-4-desktop is-3-widescreen">
                <a href={company.link} target="_blank">
                  <Card py={2} px={4}>
                    <Box maxWidth={300} m="auto">
                      <PreviewCompatibleImage imageInfo={company.img} />
                    </Box>
                    {/* <Paragraph fontWeight={600} pt={3}> */}
                    {/*  {company.name} */}
                    {/* </Paragraph> */}
                    {/* <Paragraph color={theme.color.greyish}> */}
                    {/*  {company.start} */}
                    {/* </Paragraph> */}
                    {/* <Paragraph> */}
                    {/*  {company.people} */}
                    {/* </Paragraph> */}
                  </Card>
                </a>
              </Column>
            ))}
          </Row>
        </Column>
        <Column col="is-8 is-offset-2">
          <Box background={theme.color.blue} width={80} height={4} mx="auto" my={5} />
          <Paragraph textAlign="center">
            {desc}
          </Paragraph>
        </Column>
      </Row>
    </Container>

  </Section>
);

export default SubsidiariesList;
